import React, { useRef, useState, useEffect, useCallback } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ItemPost from '../components/ItemPost';
import { ReactComponent as ArrowLeft } from '../img/Rectangle2.svg';
import { ReactComponent as ArrowLeftGreen } from '../img/Rectangle2green.svg';
import { ReactComponent as ArrowLeftRed } from '../img/Rectangle2red.svg';
import { ReactComponent as HorizontalArrows } from '../img/mingcute_move-fill.svg';

const ItemTypes = {
  TABLE_ROW: 'TABLE_ROW',
};

const DraggableTableRow = ({ record, index, moveRow, status }) => {
  const ref = useRef(null);
  const [isOver, setIsOver] = useState(false);

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.TABLE_ROW,
    item: { record, index, status },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemTypes.TABLE_ROW,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Move the item
      moveRow(dragIndex, hoverIndex, status);
      item.index = hoverIndex;
      // Add CSS class to indicate hovering
      setIsOver(monitor.isOver());
    },
    drop(item, monitor) {
      // Remove the hovering CSS class when dropped
      setIsOver(false);
    },
  });

  useEffect(() => {
    drag(drop(ref));
  }, [drag, drop]);

const color = record[8]?.stringValue;
const link = record[0]?.longValue || 'ID not available';

  return (
    <div ref={ref} className={`table-row ${isDragging ? 'dragging' : ''} ${isOver ? 'hovering' : ''}`}>
      <a href={link}> 
        <section>
          <div><h2>{record[1]?.stringValue}</h2></div>
          <div><span>{record[2]?.stringValue}</span></div>
          <div><small>{record[3]?.stringValue}</small></div>
          <div><small>{record[4]?.stringValue}</small></div>
          <div><small>{record[6]?.stringValue}</small></div>
          <div>
            <HorizontalArrows style={{ height: '25px', width: '25px' }}/>
          </div>
        </section>
        <section>
        <div className="triangle">
          {color === 'green' ? <ArrowLeftGreen style={{ height: '115px', width: '115px' }}/> : color === 'red' ? <ArrowLeftRed style={{ height: '115px', width: '115px' }}/> : <ArrowLeft style={{ height: '115px', width: '115px' }}/>}
        </div>
        </section>
      </a>
    </div>
  );
};

function StatusColumn({ status, records, onDrop, moveRow }) {
  const dropTargetRef = useRef(null);
  const [isOver, setIsOver] = useState(false);

  const [, drop] = useDrop({
    accept: ItemTypes.TABLE_ROW,
    hover(item, monitor) {
      if (!monitor.isOver()) {
        return;
      }

      const statusOfDraggedItem = item.status;

      if (status !== statusOfDraggedItem) {
        // Add a CSS class to indicate hovering over the column
        setIsOver(true);
      } else {
        setIsOver(false);
      }
    },
    drop(item, monitor) {
      const statusOfDraggedItem = item.status;

      if (status !== statusOfDraggedItem) {
        // If dropped on a different status column, trigger onDrop
        onDrop(status, item);
      }

      // Remove the hovering CSS class when dropped
      setIsOver(false);
    },
  });

  drop(dropTargetRef);

  return (
    <div className={`itemcolumn dropzone ${isOver ? 'hovering' : ''}`} ref={dropTargetRef}>
      <h3 style={{ cursor: 'default' }}>{status}</h3>
      <div className="itemblock">
        {records.map((record, index) => (
          <DraggableTableRow
            key={index}
            index={index}
            record={record}
            moveRow={moveRow}
            status={status}
          />
        ))}
      </div>
    </div>
  );
}

function NewsFeed() {
  const [records, setRecords] = useState([]);
  const [backlog, setBacklog] = useState([]);
  const [inProgress, setInProgress] = useState([]);
  const [completed, setCompleted] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      let emailId = localStorage.getItem("emailId");
      if (emailId) {
        emailId = emailId.replace(/^"(.*)"$/, '$1');
      }
      if (!emailId) {
        console.error('Email ID not found in local storage');
        return;
      }

      const filterValueEmail = encodeURIComponent(emailId);
      const response = await fetch(`https://nstjfqisb1.execute-api.us-east-1.amazonaws.com/getItem?userID=${filterValueEmail}&type=Proyecto`); // Add your API endpoint here
      if (!response.ok) {
        throw new Error('Failed to fetch records');
      }
      const jsonData = await response.json();

      if (Array.isArray(jsonData.result)) {
        setRecords(jsonData.result);
      } else if (jsonData.message) {
        console.error('Error message from server:', jsonData.message);
      } else {
        console.error('Invalid data format:', jsonData);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, []);

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 2000);

    return () => clearInterval(interval);
  }, [fetchData]);

  useEffect(() => {
    const sortRecordsByPosition = (records) => {
      return records.sort((a, b) => a[9]?.longValue - b[9]?.longValue);
    };

    if (records) {
      const backlogRecords = sortRecordsByPosition(records.filter(record => record[7]?.stringValue === 'Backlog'));
      const inProgressRecords = sortRecordsByPosition(records.filter(record => record[7]?.stringValue === 'In Progress'));
      const completedRecords = sortRecordsByPosition(records.filter(record => record[7]?.stringValue === 'Completed'));

      setBacklog(backlogRecords);
      setInProgress(inProgressRecords);
      setCompleted(completedRecords);
    }
  }, [records]);

  const handleDrop = async (status, item) => {
    const recordId = item.record[0]?.longValue;
    const newStatus = status;
    const newPosition = item.index;
  
    // Get the records of the new status column
    let newStatusRecords;
    switch (newStatus) {
      case 'Backlog':
        newStatusRecords = backlog;
        break;
      case 'In Progress':
        newStatusRecords = inProgress;
        break;
      case 'Completed':
        newStatusRecords = completed;
        break;
      default:
        break;
    }
  
    // Update the position of the item in the new status column
    newStatusRecords.splice(newPosition, 0, item.record);
    updatePositions(newStatusRecords);
  
    try {
      const response = await fetch('https://nstjfqisb1.execute-api.us-east-1.amazonaws.com/updateItem', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: recordId, status: newStatus, position: newPosition }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update status:', response.statusText);
      }
  
      const updatedRecords = await fetchData();
      setRecords(updatedRecords);
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const updatePositions = async (records) => {
    const updatedRecords = records.map((record, index) => ({
      id: record[0]?.longValue,
      position: index + 1,
    }));

    console.log('Sending updated records:', JSON.stringify(updatedRecords));

    try {
      const response = await fetch('https://nstjfqisb1.execute-api.us-east-1.amazonaws.com/updateItem', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedRecords),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Failed to update positions:', errorData);
      }
    } catch (error) {
      console.error('Error updating positions:', error);
    }
  };

  const moveRow = (dragIndex, hoverIndex, status) => {
    console.log(`Moving row within ${status} from ${dragIndex} to ${hoverIndex}`);
    const newRecords = { backlog, inProgress, completed };

    const moveArrayItem = (array, fromIndex, toIndex) => {
      const item = array.splice(fromIndex, 1)[0];
      array.splice(toIndex, 0, item);
    };

    switch (status) {
      case 'Backlog':
        moveArrayItem(newRecords.backlog, dragIndex, hoverIndex);
        setBacklog([...newRecords.backlog]);
        updatePositions(newRecords.backlog);
        break;
      case 'In Progress':
        moveArrayItem(newRecords.inProgress, dragIndex, hoverIndex);
        setInProgress([...newRecords.inProgress]);
        updatePositions(newRecords.inProgress);
        break;
      case 'Completed':
        moveArrayItem(newRecords.completed, dragIndex, hoverIndex);
        setCompleted([...newRecords.completed]);
        updatePositions(newRecords.completed);
        break;
      default:
        break;
    }
  };

  let type = "Proyecto";
  let parent = "main";
  let status = "Backlog";
  let color = "grey";

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="itemlist">
        <main>
          <StatusColumn status="Backlog" records={backlog} onDrop={handleDrop} moveRow={moveRow} />
          <StatusColumn status="In Progress" records={inProgress} onDrop={handleDrop} moveRow={moveRow} />
          <StatusColumn status="Completed" records={completed} onDrop={handleDrop} moveRow={moveRow} />
        </main>
        <ItemPost type={type} parent={parent} status={status} color={color} />
      </div>
    </DndProvider>
  );
}

export default NewsFeed;
