import React, { useState, useEffect } from 'react'; // Import useState from react
import './App.css';
import { BrowserRouter, Routes, Route, Link, NavLink } from 'react-router-dom';
import ItemListProyects from './components/ItemListProyects'; 
import Signup from "./components/Signup";
import Login from './components/Login';
import Confirmation from './components/Confirmation';
import Dashboard from './pages/Dashboard';
import Proyects from './pages/Proyects';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Account } from './components/Account';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-YBVJD9ZV1V');

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Define isLoggedIn state here
  return (
            <DndProvider backend={HTML5Backend}>
              <Account>
                <BrowserRouter>
                  <main>
                    <Routes>
                      <Route path="/proyects/:proyectID" element={<ItemListProyects />} />
                      <Route path="/" element={<Signup />} />
                      <Route path="login" element={<Login />} />
                      <Route path="confirmation" element={<Confirmation />} />
                      <Route path="dashboard" element={<Dashboard />} />
                      <Route path="proyects" element={<Proyects />} />
                    </Routes>
                  </main>
                </BrowserRouter>
              </Account>
            </DndProvider>
  );
}

export default App;
