// awsConfig.js
import AWS from 'aws-sdk';

AWS.config.update({
  accessKeyId: 'AKIAWAP5ISNGDUKMROON',
  secretAccessKey: '2OmvHf2jwJnfCotHkorTk+sJRgiOP4/NP1VaEv0q',
  region: 'us-east-1', // Replace with your AWS region
});

export const s3 = new AWS.S3();
