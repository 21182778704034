import React, { useState, useEffect } from 'react';
import newitem from '../img/eos-icons_content-new.png';
import closesidebar from '../img/material-symbols_close.png';

const formattedTimestamp = new Date().toISOString().slice(0, 19).replace("T", " ");

const YourComponent = ({ type, parent, status, color }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [formData, setFormData] = useState({
    itemName: '',
    description: '',
    type: type,
    sprint: '',
    parent: parent,
    status: status,
    color: color,
    timestamp: formattedTimestamp,
  });

  const storedDataUserId = JSON.parse(localStorage.getItem('emailId'));
  const storedDataEmail = JSON.parse(localStorage.getItem('emailData'));

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
      userID: storedDataUserId,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://nstjfqisb1.execute-api.us-east-1.amazonaws.com/createItem', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      console.log(data, formData);

      // Reset form fields
      setFormData({
        itemName: '',
        description: '',
        sprint: '',
        timestamp: formattedTimestamp,
      });

      // Toggle sidebar
      toggleSidebar();

      // Trigger custom event to notify about the submission
      document.dispatchEvent(new Event('formSubmitted'));

    } catch (error) {
      console.error('Error posting data:', error);
    }
  };

  const toggleSidebar = () => {
    setSidebarOpen(prevState => !prevState);
  };

  return (
    <div>
      <div className={`sidebar ${sidebarOpen ? 'active' : ''}`}>
        <div class="closesidebar">
          <img src={closesidebar} alt="Description of the image" onClick={toggleSidebar} />
        </div>
        <h2>Crear proyecto</h2>

        <form onSubmit={handleSubmit}>
          <label>Nombre del proyecto: <input type="text" name="itemName" value={formData.itemName} onChange={handleInputChange}/></label><br />
          <label>Descripción: <input type="text" name="description" value={formData.description} onChange={handleInputChange}/></label><br />
          <button type="submit">Submit</button>
        </form>
      </div>
      <img src={newitem} alt="Description of the image" onClick={toggleSidebar} />
    </div>
  );
};

const dropzoneStyle = {
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
  margin: '20px 0',
};

export default YourComponent;
